// Webpack Imports
import 'jquery-validation';
import Shuffle from 'shufflejs';  
import Cookies from 'js-cookie';
import Stickyfill from 'stickyfilljs';

$.extend($.validator.messages, {
	required: "To pole jest wymagane.",
	remote: "Proszę o wypełnienie tego pola.",
	email: "Proszę o podanie prawidłowego adresu email.",
	url: "Proszę o podanie prawidłowego URL.",
	date: "Proszę o podanie prawidłowej daty.",
	dateISO: "Proszę o podanie prawidłowej daty (ISO).",
	number: "Proszę o podanie prawidłowej liczby.",
	digits: "Proszę o podanie samych cyfr.",
	creditcard: "Proszę o podanie prawidłowej karty kredytowej.",
	equalTo: "Proszę o podanie tej samej wartości ponownie.",
	extension: "Proszę o podanie wartości z prawidłowym rozszerzeniem.",
	phonePL: "Proszę o podanie prawidłowego numeru telefonu",
	maxlength: $.validator.format( "Proszę o podanie nie więcej niż {0} znaków." ),
	minlength: $.validator.format( "Proszę o podanie przynajmniej {0} znaków." ),
	rangelength: $.validator.format( "Proszę o podanie wartości o długości od {0} do {1} znaków." ),
	range: $.validator.format( "Proszę o podanie wartości z przedziału od {0} do {1}." ),
	max: $.validator.format( "Proszę o podanie wartości mniejszej bądź równej {0}." ),
	min: $.validator.format( "Proszę o podanie wartości większej bądź równej {0}." ),
	pattern: $.validator.format( "Pole zawiera niedozwolone znaki." )
});

( function () {
	'use strict';

	// Focus input if Searchform is empty
	[].forEach.call( document.querySelectorAll( '.search-form' ), ( el ) => {
		el.addEventListener( 'submit', function ( e ) {
			var search = el.querySelector( 'input' );
			if ( search.value.length < 1 ) {
				e.preventDefault();
				search.focus();
			}
		} );
	} );
 
	const popoverTriggerList = [].slice.call( document.querySelectorAll( '[data-bs-toggle="popover"]' ) );
	popoverTriggerList.map( function ( popoverTriggerEl ) {
		return new mdb.Popover( popoverTriggerEl, {
			trigger: 'focus',
		} );
	} );
 
	$('[name="workshop_form[invoice]"]').on('change', (e) => {
		let $invoiceHelp = $('#invoiceHelp'); 
		$invoiceHelp.removeClass('d-none');
		if (!(e.currentTarget && e.currentTarget.checked === true)) {
			$invoiceHelp.addClass('d-none');
		}  
	});

	$('[name="workshop_form[event]"]').on('change', (e) => {
		let $workshop = $('#workshop'); 
		let $workshopTrip = $('#workshopTrip'); 
		if (e.currentTarget && e.currentTarget.selectedOptions[0].dataset.type === 'workshop') {
			$workshop.removeClass('d-none');
		} else {
			$workshop.addClass('d-none');
			if (e.currentTarget && e.currentTarget.selectedOptions[0].dataset.type === 'workshop_trip') {
				$workshopTrip.removeClass('d-none');
			} else {
				$workshopTrip.addClass('d-none');
			}
		}
	}).trigger('change');

	$('form.form').on('click', 'button.submit', (e) => {
		$(e.delegateTarget).trigger('submit');

		return false;
	});


	$(document).on("keydown", "form.quick-search-form", function(event) { 
		return event.key != "Enter";
	});
	
	$('form.form').each(function () {
		$(this).validate({
			errorClass: 'is-invalid',
			errorElement: 'div',
			errorPlacement: function ( error, element ) {
				error.addClass( "invalid-feedback" );

				if ( element.prop( "type" ) === "checkbox" ) {
					error.insertAfter( element.next( "label" ) );
				} else {
					error.insertAfter( element );
				}
			},
			highlight: function ( element, errorClass, validClass ) {
				$( element ).addClass( "is-invalid" ).removeClass( "is-valid" );
			},
			unhighlight: function (element, errorClass, validClass) {
				$( element ).addClass( "is-valid" ).removeClass( "is-invalid" );
			},
			invalidHandler: function(event, validator) {
				$(this).addClass('was-validated');
			}
		});
	})

	let addSearchListener = function (shuffleObj, searchInput) {
		if (searchInput) {
			searchInput.addEventListener('keyup', function(e) {
				const searchText = e.target.value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
				shuffleObj.filter((element, shuffle) => {
					const bodyText = element.textContent.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
					
					return bodyText.indexOf(searchText) !== -1;
				})
			});
		}
	}

	let createShuffle = function (elementId, itemSelector, searchInputId) {
		let element = document.getElementById(elementId);
		if (element) {
			let shuffle = new Shuffle(element, {
				itemSelector: itemSelector,
				buffer: 1,
				isCentered: false,
				sizer: '.sizer-selector'
			});
	
			addSearchListener(shuffle, document.getElementById(searchInputId));
		
			let addFilterButtons = function () {
				const options = document.querySelector('.filter-options');
				if (!options) {
					return;
				}
				
				const filterButtons = Array.from(options.children);
				filterButtons.forEach((button) => {
					button.addEventListener('click', handleFilterClick, false);
				});
			}
			
			let handleFilterClick = function (evt) {
				const btn = evt.currentTarget;
				const isActive = btn.classList.contains('active');
				const btnGroup = btn.getAttribute('data-group');
				
				removeActiveClassFromChildren(btn.parentNode);
				
				let filterGroup;
				if (isActive) {
					btn.classList.remove('active');
					filterGroup = Shuffle.ALL_ITEMS;
				} else {
					btn.classList.add('active');
					filterGroup = btnGroup;
				}
				
				shuffle.filter(filterGroup);
			}
			
			let removeActiveClassFromChildren = function (parent) {
				const { children } = parent;
				for (let i = children.length - 1; i >= 0; i--) {
					children[i].classList.remove('active');
				}
			}

			addFilterButtons();	
		}
	}

	createShuffle('recipes-list-cards', '.recipe-card', 'recipe-search-input');
	createShuffle('article-list-cards', '.article-card', 'article-search-input');

	// search form listener
	$('.nav-search-link').on('click', (e) => {
		$('#header').addClass('search-form-open');
		return false;
	});
	$('.nav-search-link-close').on('click', (e) => {
		$('#header').addClass('search-transition').removeClass('search-form-open');
		return false;
	});


	// modal listener
	let modal = document.getElementById('newsletterModal');
	if (modal) {
		let wasValidated = modal.classList.contains('was-validated');
		modal.addEventListener('hidden.mdb.modal', function() {
			Cookies.set('shown_newsletter_modal', '1', {expires: 14})
		});
	
		if ((Cookies.get('shown_newsletter_modal') != '1' && Cookies.get('em_') != '1') || wasValidated ) {
			setTimeout(function() {
				(new mdb.Modal(modal)).show();
			}, wasValidated ? 0 : 5000)
		}
	}

	// cookie banner listener
	let cookieBanner = document.getElementById('cookieBanner');
	if (cookieBanner) {
		cookieBanner.querySelector('.close-btn').addEventListener('click', function(e) {
			cookieBanner.classList.remove('d-block');
			cookieBanner.classList.add('d-none');
			Cookies.set('shown_cookie_banenr', '1', {expires: 365});

			e.preventDefault();
		});

		if (Cookies.get('shown_cookie_banenr') != '1') {
			cookieBanner.classList.add('d-block');
			cookieBanner.classList.remove('d-none');
		}
	}
	
	var elements = document.querySelectorAll('.sticky');
	Stickyfill.add(elements); 
} )();